"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import { Box, InputBase, Stack } from "@mui/material";

import { observer, useStore } from "../../service/mobx";
import useDialogUpgrade from "../../component/Dialog/dialogs/appWide/Upgrade";
import useSignInDialog from "../../component/Dialog/dialogs/appWide/Login";

const ButtonFilled = dynamic(() => import("../../component/Button/Filled"));
const SendOnIcon = dynamic(() =>
  import("@mui/icons-material/ArrowUpwardRounded")
);

function AgentInput() {
  const [clicked, setClicked] = useState(false);
  const [userInput, setInput] = useState("");
  const [placeholder, setPlaceholder] = useState("How can Bytez help you?");
  const { agent, user } = useStore();
  const signInDialog = useSignInDialog(
    "You're signed out. Sign in to create a new thread"
  );
  const upgradeDialog = useDialogUpgrade("Upgrade for more threads");
  const router = useRouter();

  useEffect(() => {
    if (userInput === "") {
      let rounds = 0;
      let index = 0;
      const placeholders = [
        'I can help you search papers (try "1706.03762" or "attention is all you need")',
        'I can search models (try "deepseek" or "openai/whisper-large-v3-turbo")',
        'I can answer research questions (try "What does the research say about using LSTMs in self-driving cars?")'
      ];

      const id = setInterval(() => {
        const placeholder = placeholders[index++];

        if (placeholder === undefined) {
          index = 0;

          if (++rounds === 2) {
            clearInterval(id);
          }
        }

        setPlaceholder(placeholder || "How can I help you?");
      }, 6e3);

      return () => clearInterval(id);
    }
  }, [userInput]);

  return (
    <Box px={{ compact: 2, expanded: 3, large: 0 }}>
      <Stack
        position="sticky"
        bottom={0}
        useFlexGap
        spacing={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="var(--shape-md-round)"
        sx={theme => ({
          pl: 2,
          pr: 1,
          py: 1,
          border: 1,
          borderColor: "var(--outline-variant)",
          boxShadow: "var(--elevation-2)",
          transition: theme.transitions.create("all"),
          bgcolor: "var(--surface-container-lowest)",
          "&:hover": {
            bgcolor: "var(--surface-container-low)"
          }
        })}
      >
        <InputBase
          fullWidth
          autoFocus
          multiline
          maxRows={8}
          minRows={4}
          value={userInput}
          placeholder={placeholder}
          sx={{
            position: "relative",
            typography: "titleMd",
            color: "var(--surface-on-color)",
            ".Mui-disabled": {
              fontStyle: "italic",
              WebkitTextFillColor: "var(--secondary-on-container)"
            }
          }}
          inputProps={{
            sx: { color: "var(--surface-on-color)" }
          }}
          onChange={event => setInput(event.target.value)}
        />
        <Box alignSelf="flex-start">
          {userInput ? (
            <ButtonFilled
              disabled={clicked}
              sx={{ borderRadius: "var(--shape-md-round)" }}
              IconEnd={SendOnIcon}
              onClick={async () => {
                if (agent.maxThreads) {
                  if (user.isAnonymous) {
                    signInDialog();
                  } else {
                    upgradeDialog();
                  }
                } else if (userInput.trim()) {
                  setClicked(true);

                  await agent.thread.create(userInput);

                  router.push("/agent");
                }
              }}
            />
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
}

export default observer(AgentInput);
