import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/GitHub.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/SearchRounded.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/icons-material/esm/TrendingUpRounded.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/component/Button/Text.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Agent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Feed/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/view/discover/Welcome.js");
