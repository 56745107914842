"use client";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { observer, useStore } from "../../service/mobx";

function Welcome() {
  const { user } = useStore();
  const [hour, setHour] = useState("");

  useEffect(() => {
    const hour = new Date().getHours();

    setHour(
      `Good ${hour < 12 ? "morning" : hour < 17 ? "afternoon" : "evening"}${
        user.loaded
          ? user.isAnonymous
            ? ", welcome to Bytez"
            : `, ${user.name.split(" ")[0]}`
          : ""
      }`
    );
  }, [user.loaded, user.isAnonymous, user.name]);

  return (
    <Typography
      align="center"
      fontWeight="300 !important"
      typography={{ compact: "headlineLg", expanded: "displayMd" }}
    >
      {hour}
    </Typography>
  );
}

export default observer(Welcome);
