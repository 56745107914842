"use client";
import { Suspense, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { Box, Stack } from "@mui/material";
import useSWR from "swr";

import { useStore, observer } from "../../../service/mobx";
import { fetcher } from "../../../service/graph";
import HorizontalChips from "../../../component/Chip/Horizontal";
import useSignInDialog from "../../../component/Dialog/dialogs/appWide/Login";
import Skeleton from "../../../component/Skeleton";

const Divider = dynamic(() => import("@mui/material/Divider"));
const Typography = dynamic(() => import("@mui/material/Typography"));
const Collapse = dynamic(() => import("@mui/material/Collapse"));
const Masonry = dynamic(() => import("masonic").then(module => module.Masonry));
const ShowMoreIcon = dynamic(() =>
  import("@mui/icons-material/ExpandMoreRounded")
);
const ShowLessIcon = dynamic(() =>
  import("@mui/icons-material/ExpandLessRounded")
);
const SearchIcon = dynamic(() => import("@mui/icons-material/SearchRounded"));
const TuneIcon = dynamic(() => import("@mui/icons-material/SettingsRounded"));
const MenuTask = dynamic(() =>
  import("../../../component/Menu/menus/Search/Task")
);
const ButtonText = dynamic(() => import("../../../component/Button/Text"));
const ButtonOutlined = dynamic(() =>
  import("../../../component/Button/Outlined")
);
const ChipFilter = dynamic(() => import("../../../component/Chip/Filter"));

const CardExternalCodeSize2 = dynamic(() =>
  import("../../../component/Card/External").then(
    module => module.CardExternalCodeSize2
  )
);
const ModelCardSize3 = dynamic(() =>
  import("../../../component/Card/Model").then(module => module.ModelCardSize3)
);
const CardProductSize2 = dynamic(() =>
  import("../../../component/Card/Product").then(
    module => module.CardProductSize2
  )
);
const PaperCardSize3 = dynamic(() =>
  import("../../../component/Card/Paper").then(module => module.PaperCardSize3)
);

function Feed() {
  const [following, setFollowing] = useState([]);
  const [task, setTask] = useState("all");
  const { bottomSheet, device, layout, user, stars } = useStore();
  const ref = useRef();
  const sections = [
    { sort: "trending", entity: "models" },
    { sort: "trending", entity: "papers" },
    { sort: "new", entity: "papers" },
    { sort: "new", entity: "models" },
    { sort: "popular", entity: "papers" },
    { sort: "popular", entity: "models" }
  ];

  useEffect(() => {
    if (user.loaded) {
      setFollowing([
        { id: "all", name: "all" },
        ...(user.isAnonymous
          ? [
              { id: "text-generation", name: "text generation" },
              { id: "chat", name: "chat" },
              { id: "text-to-image", name: "image generation" },
              { id: "object-detection", name: "object detection" }
            ]
          : stars.tasks)
      ]);

      return () => setFollowing([]);
    }
  }, [user, user.loaded, device.isNotPhone, stars.tasks]);

  return (
    <Stack spacing={5} mt={20} pb={5}>
      <Box
        px={{ compact: 2, expanded: 0 }}
        top={{ compact: 4, expanded: 0 }}
        position="sticky"
        bgcolor="var(--surface-dim)"
        zIndex={`calc( var(--zIndex-appBar) - ${
          device.isPhone && layout.navOpen ? 1 : 0
        } )`}
        sx={theme => ({
          ...theme.fadeEdge(),
          transition:
            device.isPhone && (layout.navOpen || bottomSheet.open)
              ? undefined
              : "z-index 1s"
        })}
      >
        <HorizontalChips
          chips={[
            <TuneYourFeed key="tune" />,
            ...(user.isAnonymous === false && stars.tasks.length === 0
              ? [
                  <div key="all">
                    <Skeleton
                      width={66}
                      borderRadius="var(--shape-sm)"
                      height={{ compact: 24, expanded: 32 }}
                    />
                  </div>,
                  ...stars.starredTasks.map(({ id }) => (
                    <div key={id}>
                      <Skeleton
                        borderRadius="var(--shape-sm)"
                        height={{ compact: 24, expanded: 32 }}
                        width={parseInt(Math.random() * (120 - 60) + 60)}
                      />
                    </div>
                  ))
                ]
              : following.map(_task => (
                  <ChipFilter
                    key={_task.id}
                    label={_task.name}
                    small={device.isPhone}
                    selected={task === _task.name}
                    onClick={() => {
                      setTask(_task.name);
                      ref.current.scrollIntoView();
                    }}
                  />
                )))
          ]}
        />
      </Box>
      <Box ref={ref} overflow="hidden" px={{ compact: 2, expanded: 0 }}>
        {sections.map(({ sort, entity }) => (
          <Section
            key={sort + entity + task}
            task={task}
            sort={sort}
            entity={entity}
          />
        ))}
      </Box>
    </Stack>
  );
}

export default observer(Feed);

const Section = observer(function Section({ task, sort, entity }) {
  const [skeleton] = useState(
    new Array(6).fill().map(() => [Math.random() * (92 - 64) + 92])
  );
  const [showMore, setShowMore] = useState(false);
  const [collapsedSize, setCollapsedSize] = useState(0);
  const ref = useRef();
  const { data, isLoading } = useSWR(
    `/api/following/${task}/${entity}/${sort}`,
    fetcher
  );
  const { device } = useStore();
  const label = `${sort} ${entity}`;
  const columnCount = {
    compact: 1,
    medium: 2,
    expanded: 1,
    large: 2,
    extraLarge: 2
  }[device.size];
  const previewSize = device.isPhone ? 3 : 6;

  function measureCollapseSize() {
    const items = [
      ...ref.current.nextSibling.firstChild.firstChild.firstChild.children
    ]
      .map(element => [
        {
          top: parseInt(element.style.top),
          left: parseInt(element.style.left)
        },
        element.getBoundingClientRect().height
      ])
      .sort(([a], [b]) => (a.top === b.top ? a.left - b.left : a.top - b.top))
      .slice(0, previewSize);
    let collapsedSize = 0;

    for (const [{ top }, height] of items) {
      collapsedSize = Math.max(collapsedSize, top + height);
    }

    setCollapsedSize(collapsedSize + 72);
  }

  return device.measured === false ? null : isLoading ? (
    <Suspense>
      <Stack
        pb={2}
        pt={7}
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          height={32}
          width={184}
          borderRadius="var(--shape-xs-round)"
        />
        <Skeleton height={32} width={192} borderRadius="var(--shape-round)" />
      </Stack>
      <Masonry
        rowGutter={8}
        columnGutter={8}
        maxColumnCount={2}
        overscanBy={Infinity}
        columnCount={columnCount}
        items={skeleton.slice(0, previewSize)}
        render={({ data: [height] }) => (
          <Box width="100%">
            <Skeleton width="100%" height={height} />
          </Box>
        )}
      />
    </Suspense>
  ) : data?.length ? (
    <Suspense>
      <Stack
        pb={2}
        pt={7}
        ref={ref}
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography
          variant="titleLg"
          color="var(--surface-on-color)"
          textTransform="capitalize"
          fontWeight={{ compact: 600, expanded: 700 }}
        >
          {label}
        </Typography>
        {data.length === 12 ? (
          <ButtonText
            size="small"
            label={`search ${
              device.size === "expanded" || device.size === "compact"
                ? ""
                : label
            }`}
            IconStart={SearchIcon}
            href={`/search?type=${entity}&sort=${sort}${
              task === "all" ? "" : `&task=${task}`
            }`}
            sx={{ color: "var(--secondary-color)", textWrap: "balance" }}
          />
        ) : null}
      </Stack>
      <Collapse collapsedSize={collapsedSize} in={showMore}>
        <Masonry
          key={device.size}
          columnGutter={8}
          maxColumnCount={2}
          items={data}
          columnCount={columnCount}
          overscanBy={Infinity}
          onRender={collapsedSize === 0 ? measureCollapseSize : undefined}
          render={({ data: entity, index }) => (
            <Box
              sx={{
                visibility:
                  previewSize <= index && showMore === false
                    ? "hidden"
                    : undefined
              }}
            >
              {entity.__typename === "paper" ? (
                <CardProductSize2 tags paper={entity} />
              ) : entity.models.length ? (
                <ModelCardSize3 tags model={entity.models[0]} />
              ) : entity.label === "trending papers" ? (
                <PaperCardSize3 paper={entity} />
              ) : (
                <CardExternalCodeSize2 content={{ node: entity }} />
              )}
            </Box>
          )}
        />
      </Collapse>
      {previewSize < data.length ? (
        <Stack direction="row" alignItems="center" pt={3}>
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
          <ButtonOutlined
            label={`Show ${showMore ? "less" : "more"}`}
            IconEnd={showMore ? ShowLessIcon : ShowMoreIcon}
            onClick={() =>
              setShowMore(showMore => {
                const showingMore = !showMore;

                if (showingMore) {
                  setTimeout(() => {
                    ref.current?.scrollIntoView({
                      block: "start",
                      behavior: "smooth"
                    });
                  }, 1e3);
                }

                return showingMore;
              })
            }
            sx={{
              borderColor: "rgba(0,0,0,.1)",
              color: "var(--secondary-color)",
              transition: "all 225ms ease",
              "&:hover": {
                bgcolor: "var(--secondary-container)",
                color: "var(--secondary-on-container)"
              }
            }}
          />
          <Divider sx={{ flexGrow: 1, borderColor: "rgba(0,0,0,.1)" }} />
        </Stack>
      ) : null}
    </Suspense>
  ) : null;
});

const TuneYourFeed = observer(function TuneYourFeed() {
  const { device, menu, user } = useStore();
  const signUp = useSignInDialog("Tune your feed");
  const loading = (
    <div key="tune">
      <Skeleton
        width={150}
        borderRadius="var(--shape-sm)"
        height={{ compact: 24, expanded: 32 }}
      />
    </div>
  );

  return user.loaded ? (
    <Suspense fallback={loading}>
      <ChipFilter
        StartIcon={TuneIcon}
        small={device.isPhone}
        label="Tune your feed"
        onClick={
          user.isAnonymous
            ? signUp
            : event =>
                menu.configure({
                  anchor: event.target,
                  Component: TaskFollow,
                  sx: {
                    paper: { maxHeight: "70vh !important" },
                    menuList: { pt: 0, maxHeight: "100%" }
                  }
                })
        }
        sx={{
          mr: 0.5,
          bgcolor: "var(--primary-color)",
          "& p, svg": {
            color: "var(--primary-on-color)"
          }
        }}
      />
    </Suspense>
  ) : (
    loading
  );
});

const TaskFollow = observer(function TaskFollow() {
  const { stars, device, bottomSheet, snackbar, tasks } = useStore();
  const tasksFollowed = stars.tasks.map(task => task.name);

  return (
    <Suspense>
      <MenuTask
        key={stars.tasks.length}
        value={tasksFollowed}
        initOpen={device.isNotPhone || bottomSheet.expanded}
        onChange={(_, values) => {
          const set = new Set(tasksFollowed);
          const newState = new Set(values.map(([task]) => task));

          for (const task of set) {
            if (newState.has(task) === false) {
              const entity = tasks.map.get(task);

              stars.quickSave({ save: false, entity });
              snackbar.notify({
                line1: "Stopped following:",
                line2: task,
                actions: [
                  {
                    label: "Undo",
                    onClick() {
                      snackbar.set.open(false);
                      stars.quickSave({ save: true, entity });
                    }
                  }
                ]
              });
            }
          }

          for (const task of newState) {
            if (set.has(task) === false) {
              const entity = tasks.map.get(task);

              stars.quickSave({ save: true, entity });
              snackbar.notify({
                line1: "Following:",
                line2: task,
                actions: [
                  {
                    label: "Undo",
                    onClick() {
                      snackbar.set.open(false);
                      stars.quickSave({ save: false, entity });
                    }
                  }
                ]
              });
            }
          }
        }}
      />
    </Suspense>
  );
});
